@font-face {
  font-family: "Oxanium";
  src: url("./assets/fonts/Oxanium-Regular.eot");
  src: url("./assets/fonts/Oxanium-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Oxanium-Regular.woff2") format("woff2"),
    url("./assets/fonts/Oxanium-Regular.woff") format("woff"),
    url("./assets/fonts/Oxanium-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Palanquin";
  src: url("./assets/fonts/Palanquin-Medium.eot");
  src: url("./assets/fonts/Palanquin-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Palanquin-Medium.woff2") format("woff2"),
    url("./assets/fonts/Palanquin-Medium.woff") format("woff"),
    url("./assets/fonts/Palanquin-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Oxanium";
}
